.markdown {
  @apply text-lg leading-relaxed;
}

.markdown p,
.markdown ul,
.markdown ol,
.markdown blockquote {
  @apply my-6;
}

.markdown h2 {
  @apply text-3xl mt-12 mb-4 leading-snug;
}

.markdown h3 {
  @apply text-2xl mt-8 mb-4 leading-snug;
}

.markdown h1 {
  @apply text-4xl mt-16 mb-8 leading-none font-bold;
}
